export default {
  signup(jobalertData) {
    return fetch(`${process.env.VUE_APP_API_URL}/jobalert/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jobalertData)
    });
  }
};
