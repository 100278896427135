<template>
  <div class="apply-form__section apply-form__privacy-policy">
    <h3>{{ $t('privacyPolicy') }}</h3>
    <p>
      Sie sind damit einverstanden, dass die hidden professionals GmbH, der
      Anbieter dieser Software, Ihre Bewerbung mit allen Angaben dem Zweck
      („Online Bewerbung“) entsprechend speichert und auswertet.
    </p>
    <p>
      Die hidden professionals GmbH verpflichtet sich, mit den Daten sorgfältig
      umzugehen und die Daten, außer zum Zweck dieser Online-Bewerbung, nicht zu
      nutzen.
    </p>
    <p>
      Ihre Daten bleiben 6 Monate gespeichert. Nach diesem Zeitraum werden Ihre
      Bewerbungs- und Zugangsdaten automatisch gelöscht. Selbstverständlich
      können Sie Ihr Einverständnis jederzeit widerrufen. Senden Sie dazu eine
      E-Mail an datenschutz@hidden-professionals.de.
    </p>
    <p>
      Für die Datenverarbeitung und Speicherung, der über dieses Bewerberportal
      eingehenden Bewerbungen, ist im Sinne des Bundesdatenschutzgesetzes die
      hidden professionals GmbH, Kaiserstraße 39, 55116 Mainz, verantwortlich.
      Eine Weitergabe der Daten an Drittfirmen erfolgt nicht.
    </p>
    <p>
      Unsere ausführlichen Datenschutzbestimmungen finden Sie
      <router-link :to="{ name: 'privacyPolicy' }">hier</router-link>.
    </p>

    <div>
      <v-checkbox
        v-model="policyAccepted"
        :label="$t('acceptPrivacyPolicy')"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    policyAccepted: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
