<template>
  <v-select
    v-model="questionValue"
    outlined
    flat
    hide-details="auto"
    append-icon="mdi-briefcase-outline"
    clearable
    :placeholder="$t('pleaseSelect')"
    :items="options"
    :name="`question_item_${question.chapterIndex}_${question.sortIndex}`"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: true
    },

    question: {
      type: Object,
      required: true
    }
  },

  computed: {
    options() {
      return [
        { text: this.$t('application.skillLevel.1'), value: 1 },
        { text: this.$t('application.skillLevel.2'), value: 2 },
        { text: this.$t('application.skillLevel.3'), value: 3 },
        { text: this.$t('application.skillLevel.4'), value: 4 }
      ];
    },

    questionValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
