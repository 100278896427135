<template>
  <v-text-field
    v-model.number="fieldValue"
    type="number"
    outlined
    flat
    hide-details="auto"
    :label="fieldLabel"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    value: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },

  computed: {
    fieldLabel() {
      let label = this.localize(this.item.label);

      // TODO: use enumeration
      if (this.item.fieldValidationMode === 2) {
        label += '*';
      }

      return label;
    },

    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
