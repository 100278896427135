export default {
  setContent(state, content) {
    state.content = content;
  },

  setCurrentJob(state, joboffer) {
    state.currentJoboffer = joboffer;
  },

  unsetFilters(state) {
    state.filters = {};
  }
};
