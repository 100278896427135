<template>
  <v-dialog
    v-model="showDialog"
    transition="slide-x-transition"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        color="primary"
        class="d-none d-md-inline"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('jobs.subscribeToJobalert') }}
        <v-icon class="append-icon">mdi-bell</v-icon>
      </v-btn>

      <v-btn small color="primary" class="d-md-none" v-bind="attrs" v-on="on">
        {{ $t('jobs.subscribeToJobalert_short') }}
        <v-icon class="append-icon">mdi-bell</v-icon>
      </v-btn>
    </template>

    <v-card class="alternate">
      <v-card-title class="d-flex">
        <div class="flex-grow-1">{{ $t('jobalert.headline') }}</div>
        <div>
          <v-btn small fab class="secondary" @click="clearAndClose">
            <v-icon class="accent--text">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <p>
          {{ $t('jobalert.description') }}
        </p>

        <v-form ref="form" v-model="isValid" lazy-validation>
          <div class="mb-4">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              required
              solo
              flat
              hide-details="auto"
              :label="$t('jobalert.emailAddress') + ' *'"
            ></v-text-field>
          </div>

          <div class="mb-4">
            <v-text-field
              v-model="keyword"
              solo
              flat
              hide-details="auto"
              :label="$t('jobalert.keyword')"
            ></v-text-field>
          </div>

          <div class="mb-4" v-if="sortedDepartments.length > 0">
            <v-select
              v-model="selectedDepartments"
              multiple
              chips
              solo
              flat
              deletable-chips
              :label="$t('jobs.department') + ' *'"
              hide-details="auto"
              item-value="text"
              :items="sortedDepartments"
            ></v-select>
          </div>

          <div class="mb-4">
            <v-checkbox
              v-model="acceptPrivacyPolicy"
              hide-details="auto"
              :rules="privacyPolicyRule"
            >
              <template v-slot:label>
                {{ $t('jobalert.acceptPrivacyPolicy') }}
              </template>
            </v-checkbox>

            <div class="text--disabled text-sm mt-1">
              <i18n path="jobalert.viewPrivacyPolicy">
                <router-link
                  v-if="!customDataPrivacyUrl"
                  :to="{ name: 'privacyPolicy' }"
                  >{{ $t('jobalert.viewPrivacyPolicy_linkLabel') }}</router-link
                >

                <a v-else :href="customDataPrivacyUrl" target="_blank">{{
                  $t('jobalert.viewPrivacyPolicy_linkLabel')
                }}</a>
              </i18n>
            </div>
          </div>
        </v-form>

        <div class="text--disabled text-sm">
          * {{ $t('jobalert.mandatory') }}
        </div>
      </v-card-text>
      <v-card-actions class="justify-center justify-md-end">
        <v-btn small class="secondary" @click="clearAndClose">
          {{ $t('jobalert.cancel') }}
        </v-btn>

        <v-btn small @click="submit">
          {{ $t('jobalert.submit') }}
          <v-icon small class="append-icon">mdi-send-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { eventBus } from '@/event-bus.js';
import jobalertService from '@/services/JobalertService.js';

export default {
  data() {
    return {
      isValid: true,
      email: '',
      selectedDepartments: [],
      keyword: '',
      acceptPrivacyPolicy: false,
      showDialog: false,
      emailRules: [
        (v) => !!v || this.$t('jobalert.emailInvalid'),
        (v) => /.+@.+\..+/.test(v) || this.$t('jobalert.emailInvalid')
      ],
      privacyPolicyRule: [(v) => v || this.$t('jobalert.privacyPolicyInvalid')]
    };
  },

  computed: {
    ...mapGetters('jobsModule', {
      locations: 'getLocations',
      departments: 'getDepartments',
      careerLevels: 'getCareerLevels'
    }),

    ...mapState('companyModule', {
      slug: 'slug',
      isInternal: 'isInternal',
      customDataPrivacyUrl: 'customDataPrivacyUrl'
    }),

    ...mapState({
      currentLanguage: 'currentLanguage'
    }),

    sortedCareerLevels() {
      return [...this.careerLevels].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    },

    sortedDepartments() {
      return [...this.departments]
        .sort((a, b) => {
          return this.localize(a.name).localeCompare(this.localize(b.name));
        })
        .map((x) => {
          return {
            text: this.localize(x.name),
            value: x.id
          };
        });
    },

    sortedLocations() {
      return [...this.locations].sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    clearAndClose() {
      this.clear();
      this.showDialog = false;
    },

    displaySuccess() {
      this.$emit('success');
    },

    displayError() {
      this.$emit('error');
    },

    async submit() {
      await this.validate();

      if (this.isValid) {
        this.showDialog = false;

        const response = await jobalertService.signup({
          slug: this.slug,
          email: this.email,
          isInternal: this.isInternal,
          departments: this.selectedDepartments,
          keyword: this.keyword,
          languageKey: this.currentLanguage
        });

        const result = await response.json();
        if (result.success) {
          eventBus.$emit('jobalert-success');
        } else {
          eventBus.$emit('jobalert-error');
        }
      }
    }
  }
};
</script>
