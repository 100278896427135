<template>
  <v-select
    v-model="questionValue"
    outlined
    flat
    hide-details="auto"
    append-icon="mdi-directions-fork"
    bottom
    clearable
    :placeholder="$t('pleaseSelect')"
    :items="options"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },

    question: {
      type: Object,
      required: true
    }
  },

  computed: {
    options() {
      return [
        { text: this.$t('application.yesNoAnswer.1'), value: 1 },
        { text: this.$t('application.yesNoAnswer.0'), value: 0 }
      ];
    },

    questionValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
