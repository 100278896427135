<template>
  <v-menu
    v-model="pickerMenu"
    ref="menu"
    :close-on-content-click="false"
    :return-davlue.sync="date"
    transition="scale-transition"
    offset-y
    bottom
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        v-bind="attrs"
        v-on="on"
        outlined
        flat
        hide-details="auto"
        readonly
        append-icon="mdi-calendar"
        :label="fieldLabel"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      class="pa-0"
      first-day-of-week="1"
      :active-picker="activePicker"
    >
      <v-spacer></v-spacer>
      <v-btn text fab color="primary" @click="pickerMenu = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-btn text fab color="primary" @click="$refs.menu.save(date)">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    value: {
      type: String,
      required: false,
      default: ''
    },

    activePicker: {
      type: String,
      required: false,
      default: 'date'
    }
  },

  data: () => ({
    date: null,
    pickerMenu: false
  }),

  watch: {
    date() {
      this.$emit('input', this.formattedDate);
    }
  },

  computed: {
    fieldLabel() {
      let label = this.localize(this.item.label);

      // TODO: use enumeration
      if (this.item.fieldValidationMode === 2) {
        label += '*';
      }

      return label;
    },

    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    formattedDate() {
      if (!this.date) {
        return '';
      }

      const [year, month, day] = this.date.split('-');
      return `${day}.${month}.${year}`;
    }
  },

  mounted() {
    if (this.value && this.value !== '') {
      const [day, month, year] = this.date.split('.');
      this.date = `${year}-${month}-${day}`;
    }
  }
};
</script>
