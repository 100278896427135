<template>
  <v-bottom-sheet
    ref="sheet"
    v-model="displayBanner"
    persistent
    hide-overlay
    scrollable
    :retain-focus="false"
  >
    <div class="cookie-banner">
      <div class="container">
        <h3>{{ $t('cookieModal.headline') }}</h3>
        <p class="mt-4">
          <i18n path="cookieModal.info">
            <router-link :to="{ name: 'privacyPolicy' }">{{
              $t('jobalert.privacyPolicyLabel')
            }}</router-link>
          </i18n>
        </p>

        <div class="mt-4">
          <v-row dense>
            <v-col dense cols="12" sm="4" md="3" xl="2" class="d-flex">
              <v-switch
                v-model="allowRequired"
                disabled
                hide-details
                :label="$t('cookieModal.requiredCookies')"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="3" xl="2">
              <v-switch
                v-model="allowStatistics"
                :label="$t('cookieModal.statisticsCookies')"
              ></v-switch>
            </v-col>
            <v-col
              dense
              cols="12"
              md="6"
              xl="8"
              class="d-block d-md-flex justify-end"
            >
              <div>
                <v-btn small class="outlined mb-2 mb-md-0" @click="rejectAll">
                  {{ $t('cookieModal.reject') }}
                </v-btn>
              </div>
              <div>
                <v-btn
                  small
                  class="outlined ml-md-2 mb-2 mb-md-0"
                  @click="acceptSelection"
                >
                  {{ $t('cookieModal.allowSelected') }}
                </v-btn>
              </div>
              <div>
                <v-btn small class="ml-md-2" @click="acceptAll">
                  {{ $t('cookieModal.allowAll') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-bottom-sheet>
</template>

<script>
import { cookieMixin } from '@/mixins/cookie.js';
import { mapState } from 'vuex';

export default {
  mixins: [cookieMixin],

  data() {
    return {
      cookieName: 'cookie_prefs',
      displayBanner: false,
      allowRequired: true,
      allowStatistics: false
    };
  },

  computed: {
    ...mapState('companyModule', {
      useGoogleAnalytics: 'useGoogleAnalytics',
      googleAnalyticsId: 'googleAnalyticsId'
    })
  },

  watch: {
    displayBanner() {
      this.$nextTick(() => {
        this.$refs.sheet.showScroll();
      });
    }
  },

  mounted() {
    const cookie = this.getCookie(this.cookieName);
    this.displayBanner = !cookie;

    if (cookie) {
      const cookieValue = JSON.parse(cookie);
      if (cookieValue.consent === 'all') {
        this.initializeGoogleAnalytics();
      }
    }
  },

  methods: {
    rejectAll() {
      this.setConsent('required');
      this.displayBanner = false;
    },

    acceptSelection() {
      let consent = 'required';
      if (this.allowStatistics) {
        consent = 'all';

        this.initializeGoogleAnalytics();
      }

      this.setConsent(consent);
      this.displayBanner = false;
    },

    acceptAll() {
      this.setConsent('all');
      this.displayBanner = false;
      this.initializeGoogleAnalytics();
    },

    setConsent(mode) {
      const cookieValue = {
        date: new Date(),
        consent: mode
      };

      this.setCookie(this.cookieName, JSON.stringify(cookieValue), 30);
    },

    initializeGoogleAnalytics() {
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        'script',
        'https://www.google-analytics.com/analytics.js',
        'ga'
      );

      window.ga('create', this.googleAnalyticsId, 'auto');
      window.ga('set', 'anonymizeIp', true);
      window.ga('send', 'pageview');
    }
  }
};
</script>
