<template>
  <div class="d-block" style="width: 100%">
    <template v-if="multiple || fileResults.length === 0">
      <div
        v-if="!uploading && !completed"
        class="file-dropzone"
        @click="$refs.fileInput.click()"
        @drop.prevent="handleFileDropped"
      >
        <!-- <span v-show="dragging"> Hier loslassen um Datei hochzuladen </span> -->
        <span>
          {{ this.label }}
        </span>

        <v-spacer></v-spacer>
        <v-icon v-show="dragging">mdi-tray-arrow-down</v-icon>
        <v-icon v-show="!dragging">mdi-tray-arrow-up</v-icon>

        <input
          type="file"
          ref="fileInput"
          @change="handleFileSelected"
          style="display: none"
        />
      </div>

      <div
        v-else-if="uploading && !completed"
        style="
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          align-items: center;
          padding-left: 25px;
          padding-right: 25px;
          padding-top: 12px;
          padding-bottom: 12px;
        "
      >
        <v-progress-linear height="20" :value="progress" color="green" rounded>
          <strong>{{ progress }}%</strong>
        </v-progress-linear>
      </div>
    </template>

    <div
      v-for="item in fileResults"
      :key="`download_file_${item.objectName}`"
      class="d-flex align-center mb-2"
    >
      <div class="d-flex flex-grow-1 align-center">
        <div class="flex-grow-1">
          <v-btn
            plain
            text
            :ripple="false"
            class="px-0"
            @click="showFile(item)"
          >
            <v-icon class="mr-2">mdi-file-outline</v-icon>
            {{ item.fileName }}
          </v-btn>
        </div>

        <div>
          <v-btn plain icon @click="clearFile(item)">
            <v-icon class="mr-2">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/event-bus.js';

import axios from 'axios';

export default {
  inject: {
    form: { default: null }
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    label: {
      type: String,
      required: false,
      default: ''
    },

    accept: {
      type: String,
      required: true,
      default: '*'
    },

    rules: {
      type: Array,
      required: false,
      default: () => []
    },

    uploadEndpoint: {
      type: String,
      required: false,
      default: ''
    },

    downloadEndpoint: {
      type: String,
      required: false,
      default: ''
    },

    additionalValues: {
      type: Object,
      required: false,
      default: () => {}
    },

    filePrefix: {
      type: String,
      required: false,
      default: ''
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    dragging: false,
    uploading: false,
    completed: false,
    progress: 50,
    file: null,
    fileResults: [],
    shouldValidate: true,
    hasError: false,
    errorMessages: [],
    defaultLabel: ''
  }),

  watch: {
    file() {
      if (this.file) {
        this.uploadFile();
      }
    },

    fileResults() {
      this.$emit('input', this.fileResults);
    }
  },

  created() {
    this.form && this.form.register(this);
  },
  beforeDestroy() {
    this.form && this.form.unregister(this);
  },

  mounted() {
    if (this.value) {
      this.fileResults = this.value;
    }

    eventBus.$on('dragover', () => {
      this.dragging = true;
    });

    eventBus.$on('dragleave', () => {
      this.dragging = false;
    });

    eventBus.$on('dropped', () => {
      this.dragging = false;
    });
  },

  methods: {
    clearFile(file) {
      const url = `${process.env.VUE_APP_API_URL}/${this.uploadEndpoint}?objectName=${file.objectName}`;
      axios.delete(url);

      this.fileResults = this.fileResults.filter(
        (x) => x.objectName !== file.objectName
      );
    },

    handleFileDropped(event) {
      eventBus.$emit('dropped');
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.uploadFile(files[0]);
      }
    },

    handleFileSelected() {
      const files = this.$refs.fileInput.files;
      if (files.length > 0) {
        this.uploadFile(files[0]);
      }
    },

    uploadFile(file) {
      if (!this.multiple && this.fileResults.length > 0) {
        return;
      }

      this.uploading = true;
      this.progress = 0;
      const url = `${process.env.VUE_APP_API_URL}/${this.uploadEndpoint}`;

      const formData = new FormData();
      formData.append('file', file);
      formData.append('filePrefix', this.filePrefix);

      for (const key of Object.keys(this.additionalValues)) {
        formData.append(key, this.additionalValues[key]);
      }

      // in order to implement a progress bar when uploading a file we
      // need to use the axios package. The fetch api does not provide
      // the required functionality.
      axios
        .post(url, formData, {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.floor((loaded * 100) / total);
            this.progress = percentage;
          },
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(({ data }) => {
          data.fileName = file.name;
          this.fileResults.push(data);
          this.uploading = false;
          this.$emit('fileUploaded');
        })
        .catch(() => {
          this.uploading = false;
          this.completed = false;
          this.fileResults = [];

          eventBus.$emit(
            'errorMessage',
            'Der Upload einer Date ist fehlgeschlagen.'
          );
        });
    },

    async showFile(file) {
      if (!this.downloadEndpoint || this.downloadEndpoint === '') {
        return;
      }

      const url = `${process.env.VUE_APP_API_URL}/${this.downloadEndpoint}?objectName=${file.objectName}&contentType=${file.contentType}`;
      const { data } = await axios.get(url, { responseType: 'blob' });

      const blob = new Blob([data], { type: file.contentType });
      const fileUrl = window.URL.createObjectURL(blob);

      // var fileURL = window.URL.createObjectURL(data);
      let tab = window.open();
      tab.location.href = fileUrl;

      // const anchor = document.createElement('a');
      // document.body.appendChild(anchor);
      // anchor.href = fileUrl;
      // anchor.download = this.file.name;
      // anchor.click();

      window.URL.revokeObjectURL(fileUrl);
    },

    validate() {
      for (const rule of this.rules) {
        const ruleResult = rule(this.value);

        if (ruleResult !== true) {
          this.hasError = true;
          this.errorMessages = [ruleResult];
          return false;
        }
      }

      return true;
    },

    reset() {
      this.hasError = false;
      this.errorMessages = [];
      this.fileResults = [];
    },

    resetValidation() {
      this.hasError = false;
      this.errorMessages = [];
    }
  }
};
</script>
