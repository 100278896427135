import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import customIcons from './custom-icons.js';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: { ...customIcons }
  }
});
