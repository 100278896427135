<template>
  <v-row>
    <v-col
      cols="12"
      :md="shortApply ? 12 : 6"
      class="pb-0 d-flex"
      :class="align"
    >
      <label
        :for="`question_item_${question.chapterIndex}_${question.sortIndex}`"
      >
        {{ localize(question.questionLabel) }}
      </label>
    </v-col>

    <v-col cols="12" :md="shortApply ? 12 : 6" class="mb-4 mb-md-0">
      <component
        v-model="question.value"
        :question="question"
        :is="questionComponent"
      ></component>
    </v-col>
  </v-row>
</template>

<script>
import questionTypeEnums from '@/enum/question-type.js';

import languageQuestion from './langauge-question.vue';
import skillQuestion from './skill-question.vue';
import textQuestion from './text-question.vue';
import yesNoQuestion from './yes-no-question.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    shortApply: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    align() {
      if (this.question.questionType !== this.questionType.Text) {
        return 'align-center';
      }

      return '';
    },

    question: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    },

    questionComponent() {
      if (this.question.questionType === this.questionType.Language) {
        return 'language-question';
      } else if (this.question.questionType === this.questionType.Competency) {
        return 'skill-question';
      } else if (this.question.questionType === this.questionType.Text) {
        return 'text-question';
      } else if (this.question.questionType === this.questionType.YesNo) {
        return 'yes-no-question';
      }

      return '';
    },

    questionType() {
      return questionTypeEnums.QuestionType;
    }
  },

  components: {
    languageQuestion,
    skillQuestion,
    textQuestion,
    yesNoQuestion
  }
};
</script>
