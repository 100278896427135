<template>
  <v-textarea
    v-model="questionValue"
    outlined
    flat
    hide-details="auto"
    :placeholder="$t('pleaseProvide')"
    append-icon="mdi-comment-outline"
  ></v-textarea>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },

    question: {
      type: Object,
      required: true
    }
  },

  computed: {
    questionValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
